<template>
  <section class="availability">
    <roomTable></roomTable>
  </section>
</template>

<script>
  import roomTable from "./roomFixed.vue";
  export default {
    name: "availability",
    components: { roomTable },
    data() {
      return {};
    },
    mounted() {},
  };
</script>

<style lang="scss">
  .availability {
    &__select {
      width: auto;
    }
  }
</style>
