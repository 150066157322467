<template>
  <section class="roomCards ">
    <div class="roomCards__container">
      <div class="roomCards__roomName">
        <p>{{ room.roomName }}</p>
      </div>
      <div class="roomCards__turnContainer" v-if="morning.length">
        <div :class="['roomCards__turn ', ind.register ? 'r' : 'm']" v-for="ind in morning" :key="ind._id">
          <p class="roomCards__text roomCards__text__Room">MAÑANA</p>
          <template v-if="ind.register">
            <router-link class="roomCards__link" :to="getLink(ind._id)" target="_blank">
              <avatar class="roomCards__imgModel" :user="ind" />
              <p class="roomCards__text ">{{ ind.artisticName }}</p>
            </router-link>
          </template>
          <template v-else>
            <avatar class="roomCards__imgModel" :user="ind" />
            <p class="roomCards__text ">{{ ind.artisticName }}</p>
          </template>
        </div>
      </div>
      <div :class="`roomCards__else roomCards__text roomCards__text__dis roomCard__else--${room.isEnable !== false ? true : false}`" v-else>
        <p class="roomCards__text roomCards__text__Room">MAÑANA</p>
        <p class="roomCards__text">{{ room.isEnable !== false ? "Habilitada" : "Deshabilitada" }}</p>
      </div>
      <div class="roomCards__turnContainer" v-if="late.length">
        <div :class="['roomCards__turn ', ind.register ? 'r' : 'l']" v-for="(ind, idx) in late" :key="idx">
          <p class="roomCards__text roomCards__text__Room">TARDE</p>
          <template v-if="ind.register">
            <router-link class="roomCards__link" :to="getLink(ind._id)" target="_blank">
              <avatar class="roomCards__imgModel" :user="ind" />
              <p class="roomCards__text ">{{ ind.artisticName }}</p>
            </router-link>
          </template>
          <template v-else>
            <avatar class="roomCards__imgModel" :user="ind" />
            <p class="roomCards__text ">{{ ind.artisticName }}</p>
          </template>
        </div>
      </div>
      <div :class="`roomCards__else roomCards__text roomCards__text__dis roomCard__else--${room.isEnable !== false ? true : false}`" v-else>
        <p class="roomCards__text roomCards__text__Room">TARDE</p>
        <p class="roomCards__text">{{ room.isEnable !== false ? "Habilitada" : "Deshabilitada" }}</p>
      </div>
      <div class="roomCards__turnContainer" v-if="nigth.length">
        <div :class="['roomCards__turn ', ind.register ? 'r' : 'n']" v-for="(ind, idx) in nigth" :key="idx">
          <p class="roomCards__text roomCards__text__Room">NOCHE</p>
          <template v-if="ind.register">
            <router-link class="roomCards__link" :to="getLink(ind._id)" target="_blank">
              <avatar class="roomCards__imgModel" :user="ind" />
              <p class="roomCards__text ">{{ ind.artisticName }}</p>
            </router-link>
          </template>
          <template v-else>
            <avatar class="roomCards__imgModel" :user="ind" />
            <p class="roomCards__text ">{{ ind.artisticName }}</p>
          </template>
        </div>
      </div>
      <div :class="`roomCards__else roomCards__text roomCards__text__dis roomCard__else--${room.isEnable !== false ? true : false}`" v-else>
        <p class="roomCards__text roomCards__text__Room">NOCHE</p>
        <p class="roomCards__text">{{ room.isEnable !== false ? "Habilitada" : "Deshabilitada" }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "mobileRoomCard",
  props: {
    room: Array[Object],
  },
  data() {
    return {
      turns: ["morning", "late", "nigth"],
      morning: [],
      late: [],
      nigth: [],
    };
  },
  computed: {
    ...mapGetters("applications", {
      getAppByName: "getAppByName",
    }),
  },
  methods: {
    getLink(id) {
      const app = this.getAppByName("website");
      const response = `/nav?to=${app.link}/models/?filter=hired%26record=${id}%23app`;
      return response;
    },
  },
  beforeMount: async function() {
    this.room.users.forEach((e) => {
      if (e.workShift.name === "morning") this.morning.push(e);
      if (e.workShift.name === "late") this.late.push(e);
      if (e.workShift.name === "night") this.nigth.push(e);
    });
  },
};
</script>
<style lang="scss">
.roomCards {
  &__container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1px;
  }
  &__text {
    font-weight: bold;
    margin: 0 5px 0 5px;
    margin-top: 5px;
    font-size: 12px;
    text-align: center;

    &__Room {
      display: flex;
      padding: 0 5px;
      border-right: #00000052 2px solid;
      width: 70px;
      font-weight: bold;
    }
    &__dis {
      display: flex;
      color: gray;
    }
  }
  &__roomName {
    margin: 20px 0 5px 0;
    text-align: center;
    box-shadow: $dshadow;
    color: $primary-color;
    text-transform: uppercase;
    font-weight: bold;
    padding: 8px;
  }
  &__turn {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    width: auto;
    height: 42px;
    margin: 5px 0;
  }
  &__else {
    text-align: center;
    padding: 10px;
    width: auto;
    margin: 5px 0;
    &--true {
      background-color: $green-color;
      color: $camouflage-green;
    }
    &--false {
      background-color: $ghost;
      color: $black;
    }
  }
  &__link {
    display: flex;
    width: 100%;
    color: black;
  }
  .m {
    background-color: $blue-primary;
  }
  .l {
    background-color: $yellow-color;
  }
  .n {
    background-color: $gray-color;
  }
  .r {
    background-color: rgba(0, 0, 0, 0.043);
    & :hover {
      text-decoration: none;
    }
  }
  &__else,
  &__roomName,
  &__turn {
    width: auto;
    border-radius: 5px;
  }
  &__imgModel {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: solid white 2px;
    margin: 3px 2px;
    margin-right: 5px;
  }
  @media screen and (min-width: 420px) {
    &__imgModel {
      width: 30px;
      height: 30px;
    }
    &__text {
      font-size: 15px;
      &__Room {
        width: 25%;
        max-width: 100px;
      }
    }
  }
}
</style>
