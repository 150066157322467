<template>
  <section class="roomCard ">
    <div class="roomCard__container">
      <div class="roomCard__roomName">
        <p>{{ getNameRoom.name }}</p>
      </div>
      <div class="roomCard__turnContainer" v-if="morning.length">
        <div :class="['roomCard__turn ', ind.register ? 'r' : 'm']" v-for="ind in morning" :key="ind._id">
          <template v-if="ind.register">
            <router-link class="roomCard__link" :to="getLink(ind._id)" target="_blank">
              <avatar class="roomCard__imgModel" :user="getUser(ind)" />
              <p class="roomCard__text">{{ ind.artisticName }}</p>
            </router-link>
          </template>
          <template v-else>
            <avatar class="roomCard__imgModel" :user="getUser(ind)" />
            <p class="roomCard__text">{{ ind.artisticName }}</p>
          </template>
        </div>
      </div>
      <div
        :class="`roomCard__text roomCard__else roomCard__else--${getNameRoom.isEnable !== false ? true : false}`"
        v-else
        v-html="getNameRoom.isEnable !== false ? 'Habilitada' : 'Deshabilitada'"
      ></div>
      <div class="roomCard__turnContainer" v-if="late.length">
        <div :class="['roomCard__turn', ind.register ? 'r' : 'l']" v-for="(ind, idx) in late" :key="idx">
          <template v-if="ind.register">
            <router-link class="roomCard__link" :to="getLink(ind._id)" target="_blank">
              <avatar class="roomCard__imgModel" :user="getUser(ind)" />
              <p class="roomCard__text">{{ ind.artisticName }}</p>
            </router-link>
          </template>
          <template v-else>
            <avatar class="roomCard__imgModel" :user="getUser(ind)" />
            <p class="roomCard__text">{{ ind.artisticName }}</p>
          </template>
        </div>
      </div>
      <div
        :class="`roomCard__text roomCard__else roomCard__else--${getNameRoom.isEnable !== false ? true : false}`"
        v-else
        v-html="getNameRoom.isEnable !== false ? 'Habilitada' : 'Deshabilitada'"
      ></div>
      <div class="roomCard__turnContainer" v-if="nigth.length">
        <div :class="['roomCard__turn ', ind.register ? 'r' : 'n']" v-for="(ind, idx) in nigth" :key="idx">
          <template v-if="ind.register">
            <router-link class="roomCard__link" :to="getLink(ind._id)" target="_blank">
              <avatar class="roomCard__imgModel" :user="getUser(ind)" />
              <p class="roomCard__text">{{ ind.artisticName }}</p>
            </router-link>
          </template>
          <template v-else>
            <avatar class="roomCard__imgModel" :user="getUser(ind)" />
            <p class="roomCard__text">{{ ind.artisticName }}</p>
          </template>
        </div>
      </div>
      <div
        :class="`roomCard__text roomCard__else roomCard__else--${getNameRoom.isEnable !== false ? true : false}`"
        v-else
        v-html="getNameRoom.isEnable !== false ? 'Habilitada' : 'Deshabilitada'"
      ></div>
    </div>
  </section>
</template>

<script>
  import { mapGetters } from "vuex";
  export default {
    name: "roomCard",
    props: {
      room: Array[Object],
    },
    data() {
      return {
        turns: ["morning", "late", "night"],
        morning: [],
        late: [],
        nigth: [],
        rooms: this.room,
      };
    },
    computed: {
      getNameRoom: function() {
        const number = this.rooms?.roomName.match(/\d+/gi) || "";
        return { name: `${this.rooms?.roomName.match(/[a-z]+/gi)} ${number}`.trim(), isEnable: this.room.isEnable };
      },
      ...mapGetters("applications", {
        getAppByName: "getAppByName",
      }),
    },
    methods: {
      getLink(id) {
        const app = this.getAppByName("website");
        const response = `/nav?to=${app.link}/models/?filter=hired%26record=${id}%23app`;
        return response;
      },
      getUser(ind) {
        return { ...ind, role: "model" };
      },
    },
    beforeMount: async function() {
      this.room.users.forEach((e) => {
        if (e.workShift.name === this.turns[0]) this.morning.push(e);
        if (e.workShift.name === this.turns[1]) this.late.push(e);
        if (e.workShift.name === this.turns[2]) this.nigth.push(e);
      });
    },
  };
</script>

<style lang="scss">
  .roomCard {
    &__container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      justify-content: end;
      gap: 8px;
      padding-left: 100px;
      :nth-child(0) {
        position: absolute;
        left: 0;
      }
    }
    &__text {
      width: 59%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-size: 13px;
      margin-top: 7px;
      margin-left: 3px;
      text-transform: capitalize;
    }
    &__roomName {
      margin: 10px 0;
      white-space: nowrap;
      text-align: center;
      box-shadow: $shadow-little;
      color: $primary-color;
      height: 38px;
      text-transform: uppercase;
      width: 80px;
      font-weight: bold;
      padding: 8px;
    }
    &__turn {
      display: flex;
      padding: 2px 5px;
      height: 38px;
      width: 145px;
      margin: 10px 0;
      box-shadow: $shadow-little;
    }
    &__link {
      display: flex;
      width: 100%;
      color: black;
    }
    .m {
      background-color: $blue-primary;
    }
    .l {
      background-color: $yellow-color;
    }
    .n {
      background-color: $gray-color;
    }
    .r {
      background-color: rgba(0, 0, 0, 0.043);
      & :hover {
        text-decoration: none;
      }
    }
    &__else {
      box-shadow: $shadow-little;
      text-align: center;
      padding: 10px;
      width: auto;
      height: 38px;
      margin: 10px 0;
      &--true {
        background-color: $green-color;
        color: $camouflage-green;
      }
      &--false {
        background-color: $ghost;
        color: $black;
      }
    }
    &__else,
    &__roomName,
    &__turn {
      border-radius: 5px;
    }
    & &__imgModel {
      width: 35px;
      height: 35px;
      border-radius: 100%;
      border: solid white 2px;
      margin-right: 5px;
    }
  }
  @media screen and (min-width: 655px) {
    .roomCard {
      &__turn {
        width: 100%;
      }
    }
  }
  @media screen and (min-width: 800px) {
    .roomCard {
      &__turn {
        width: 98%;
      }
    }
  }

  @media screen and (min-width: ($tablet_width + 150px)) {
    .roomCard {
      &__turn {
        width: 228px;
        gap: 15px;
      }
      &__else {
        width: 228px;
      }
    }
  }
</style>
