<template>
  <section class="percent">
    <div class="percent__card gcard">
      <div class="percent__cardTotal">
        <div class="percent__itemRooms">
          <div class="percent__cardTitle">
            <p class="percent__legendTitle">TOTAL HABITACIONES</p>
            <span class="percent__totalCount percent__totalCount--green">{{ allRooms }}</span>
          </div>
          <div class="percent__cardTitle">
            <p class="percent__legendTitle">HABITACIONES HABILITADAS</p>
            <span class="percent__totalCount percent__totalCount--green">{{ allRoomsIsEnable }}</span>
          </div>
        </div>
        <fieldset class="percent__cardTotalModels">
          <legend class="percent__cardTitle">
            <p class="percent__legendTitle">CANTIDAD DE MODELOS:</p>
            <span class="percent__totalCount percent__totalCount--red">{{ totalModels }}</span>
          </legend>
          <div class="percent__totalModels" v-for="(citys, idx) in cities" :key="citys + idx">
            <p class="percent__totalModelsName">{{ citys.cityName }}</p>
            <span class="percent__totalCount percent__totalCount--gray">{{ citys.cityTotalModels }}</span>
          </div>
        </fieldset>
        <fieldset class="percent__cardTotalRooms percent__cardTotalRooms--right">
          <legend class="percent__cardTitle">HABITACIONES DISPONIBLES:</legend>
          <div class="percent__totalModels" v-for="(turn, idx) in totalRooms" :key="turn + idx">
            <p class="percent__totalModelsName">{{ workShifts[turn._id] }}</p>
            <span class="percent__totalCount percent__totalCount--green">{{ turn.total }}</span>
          </div>
        </fieldset>
        <div class="percent__itemDropdown" @click="showDropdown = !showDropdown">
          <p class="percent__itemName">{{ !showDropdown ? "Ver más" : "Ver menos" }}</p>
          <i :class="'percent____contentDropdown fa ' + (!showDropdown ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up')"></i>
        </div>
      </div>
      <div class="percent__cardCity" v-if="showDropdown">
        <div class="percent__cardCities" v-for="(citys, idx) in cities" :key="'1' + idx">
          <div class="percent__cardHead">
            <h1 class="percent__cardTitle">{{ citys.cityName }}</h1>
            <span class="percent__line"></span>
          </div>
          <div class="percent__cardOfficeContent">
            <div class="percent__cardOffice" v-for="(office, idx) in citys.office" :key="'2' + idx">
              <div class="percent__cardHead">
                <h1 class="percent__cardTitle">{{ office.officeName }}</h1>
                <span class="percent__totalCount percent__totalCount--gray">{{ office.totalModels }}</span>
              </div>
              <p class="percent__contentTitle">Modelos</p>
              <div class="percent__contentCardTurn">
                <div
                  :class="`percent__cardTurn percent__cardTurn--${workShift._id}`"
                  v-for="(workShift, idx) in office.schedule"
                  :key="workShift + idx"
                >
                  <span class="percent__totalName">{{ workShifts[workShift._id] }}:</span>
                  <span class="percent__total">
                    <strong>{{ workShift.total }}</strong>
                  </span>
                </div>
              </div>
              <div class="percent__roomAvailable ">
                <p class="percent__totalName">Habitaciones disponibles:</p>
              </div>
              <div class="percent__contentCardRoom ">
                <div class="percent__cardRoom" v-for="(turn, idx) in office.workShifts" :key="turn._id + idx">
                  <span class="percent__totalName">{{ workShifts[turn._id] }}:</span>
                  <span class="percent__total">
                    <strong>{{ turn.total }}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: {
    query: Object,
  },
  data() {
    return {
      workShifts: this.$global.dictionary.workShifts_es_auto,
      totalModels: 0,
      allRooms: 0,
      allRoomsIsEnable: 0,
      totalRooms: {
        morning: { _id: "morning", total: 0 },
        late: { _id: "late", total: 0 },
        night: { _id: "night", total: 0 },
      },
      cities: [],
      showDropdown: false,
    };
  },
  methods: {
    resetAll() {
      this.totalModels = 0;
      this.allRooms = 0;
      this.allRoomsIsEnable = 0;
      this.totalRooms.morning.total = 0;
      this.totalRooms.late.total = 0;
      this.totalRooms.night.total = 0;
      this.cities = [];
    },
    workShiftsAvailable(room) {
      let newSchedule = {
        morning: { _id: "morning", total: room.length },
        late: { _id: "late", total: room.length },
        night: { _id: "night", total: room.length },
      };
      let _auxTotal = Object.assign({}, this.totalRooms);
      room.forEach(({ schedule }) => {
        if (schedule.length) {
          schedule.forEach((turn) => {
            Object.keys(newSchedule).forEach((key) => {
              if (turn._id === key) {
                newSchedule[key].total -= turn.total;
              }
            });
          });
        }
      });
      Object.keys(_auxTotal).forEach((key) => {
        _auxTotal[key].total += newSchedule[key].total;
      });
      this.totalRooms = _auxTotal;
      return Object.keys(newSchedule).map((key) => newSchedule[key]);
    },
    sortWorkShifts(schedule) {
      const workShifts = {
        morning: { _id: "morning", total: 0 },
        late: { _id: "late", total: 0 },
        night: { _id: "night", total: 0 },
      };

      schedule.forEach((turn) => {
        if (workShifts[turn._id]) {
          workShifts[turn._id].total = turn?.total || 0;
        }
      });

      return Object.values(workShifts);
    },
    async getTotals() {
      let query = this.getQueryString;
      const {
        data: { totals, error },
      } = await this.$axios.get(`availability/totals?${query}`);
      if (error) {
        this.$alerts.dataSendError({ message: error });
        return;
      }
      this.resetAll();
      const _cities = totals.map((citie) => {
        return {
          cityName: citie.cityName,
          cityTotalModels: citie.models.length,
          office: citie.office.map((office) => {
            return {
              officeName: office.officeName,
              totalModels: office.models.length,
              schedule: this.sortWorkShifts(office.schedule),
              workShifts: this.workShiftsAvailable(office.rooms.filter((room) => room.isEnable !== false)),
              cityRoomsEnable: office.rooms.filter((room) => room.isEnable !== false).length,
              cityTotalRooms: office.rooms.length,
            };
          }),
        };
      });
      this.cities = _cities;
      this.cities.forEach(({ office, cityTotalModels }) => {
        this.totalModels += cityTotalModels;
        office.forEach(({ cityTotalRooms, cityRoomsEnable }) => {
          this.allRooms += cityTotalRooms;
          this.allRoomsIsEnable += cityRoomsEnable;
        });
      });
    },
  },
  computed: {
    getQueryString() {
      const query = this.query;
      let _query = "";
      Object.keys(query).forEach((key) => {
        _query += `&${key}=${query[key]}`;
      });
      return _query;
    },
  },
  beforeMount() {
    this.getTotals();
  },
  watch: {
    query: async function() {
      await this.getTotals();
    },
  },
};
</script>
<style lang="scss">
.percent {
  padding: $mpadding 0;
  &__card {
    padding: $mpadding $mpadding/1.5;
    width: 100%;
    height: auto;
  }
  &__cardTotalModels,
  &__cardTotalRooms {
    margin: 0 $mpadding/3;
    padding: 0.25em 0.5em 0.6em;
    border: 1px solid $lipstick;
    border-radius: $border-radius-bootstrap;
  }
  &__cardTitle {
    @include Flex(row);
    width: fit-content;
    padding: 0 $mpadding/3;
    font-family: $sec_font;
    font-weight: normal;
    font-size: 17px;
    color: $black;
  }
  &__cardTotalRooms,
  &__cardTotalModels,
  &__cardList {
    @include Flex(row, space-around);
    flex-wrap: wrap;
  }
  &__cardCities {
    padding: $mpadding 0;
  }
  &__itemDropdown {
    margin-top: 10px;
    cursor: pointer;
    &:hover {
      font-weight: 600;
      text-decoration: underline;
    }
  }
  &__itemName {
    display: inline-flex;
    padding: 0 $mpadding/2;
  }
  &____contentDropdown {
    font-size: 15px;
    color: $lipstick;
    padding: $mpadding/3 0 $mpadding/3 $mpadding/3;
  }
  &__totalModels,
  &__cardHead {
    @include Flex(row, space-between, center);
    margin-bottom: $mpadding/3;
  }
  &__cardRoom,
  &__cardTurn {
    text-align: center;
    padding: 2px $mpadding/3;
    color: $black;
    min-width: 31%;
    border-radius: $border-radius-bootstrap;
    &--morning {
      background-color: $blue-primary;
    }
    &--late {
      background-color: $yellow-color;
    }
    &--night {
      background-color: $gray-edward;
    }
  }
  &__totalName {
    margin: 0 5px 0 0;
  }
  &__cardRoom {
    background-color: $green-color;
    color: $camouflage-green;
  }
  &__itemRooms {
    @include Flex(row, flex-start);
    flex-wrap: wrap;
    padding: 0 $mpadding $mpadding;
    width: 100%;
  }
  &__line {
    display: block;
    width: 90%;
    height: 1px;
    background-color: $silver;
  }
  &__cardOffice {
    padding: $mpadding/1.5;
    margin: $mpadding/1.5 0;
    background-color: $read_only_bg;
    border-radius: 5px;
  }
  &__roomAvailable {
    padding-top: $mpadding/3;
  }
  &__contentTitle,
  &__contentCardRoom,
  &__contentCardTurn,
  &__roomAvailable {
    @include Flex(row, space-between);
    font-size: 0.8em;
  }
  &__totalCount {
    @include Flex(row);
    margin: 0 $mpadding/3;
    width: 24px;
    height: 22px;
    font-family: $first_font;
    font-size: 15px;
    border-radius: 2px;
    color: $white;
    &--red {
      background-color: $lipstick;
    }
    &--gray {
      background-color: $chicago;
    }
    &--green {
      background-color: $japanese_laurel-bg;
    }
  }
  &__totalModelsName {
    font-size: 14px;
    font-weight: 600;
  }
}
@media screen and (min-width: $pocket_width) {
  .percent {
    &__cardOfficeContent {
      @include Flex(row, flex-start);
      flex-wrap: wrap;
    }
    &__cardOffice {
      margin: $mpadding/5;
      width: 48.5%;
    }
  }
}
@media screen and (min-width: $tablet_width) {
  .percent {
    &__cardTotal {
      @include Flex(row, space-between);
      flex-wrap: wrap;
      position: relative;
    }
    &__cardTotalRooms {
      &--right {
        margin-right: 35px;
      }
      min-width: 40%;
    }
    &__cardTotalModels {
      min-width: 50%;
    }
    &__itemName {
      display: none;
    }
    &____contentDropdown {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 30px;
    }
    &__cardOffice {
      margin: $mpadding/4;
      width: 32%;
    }
  }
}
</style>
