<template>
  <section class="filters">
    <div class="filters__filters gcard">
      <h3 class="filters__title sf">{{ title }}</h3>
      <div class="filters__inputs">
        <div class="filters__cont" ginput>
          <label for="search">
            <iconic :name="!loading ? 'search' : 'gloading'" />
          </label>
          <input class="filters__input" v-model="search" name="search" @input="setFilter" type="text" :placeholder="placeholder" @keyup="onSearch" />
        </div>
        <slot name="calendar"></slot>
        <div class="filters__cont" v-if="$userData.role === 'superadmin' || !role">
          <select
            :disabled="$userData.role === 'coordinator' && !$userData.isSpecialFunctions"
            ginput
            v-model="city"
            @change="changeCity($event), emitChange()"
            name="city"
          >
            <option value="" selected>Todas las ciudades</option>
            <option v-for="(citie, idx) in $cities" :key="idx" :value="citie">{{ citie }}</option>
          </select>
        </div>
        <div class="filters__cont" v-if="$userData.role === 'superadmin' || !role">
          <select id="id" ginput v-model="office" :disabled="!offices.length" name="office" @input="setFilter" @change="emitChange">
            <option value="" selected>Todas las sedes</option>
            <option v-for="office in offices" :key="office._id" :value="office.officeName">{{ office.officeName }}</option>
          </select>
        </div>
        <div class="filters__cont" clearFilter v-if="(($userData.role === 'superadmin' || !role) && cleanFilter) || flag">
          <button class="filters__delete" @click="deleteFilter">
            <i class="fa fa-times"></i>
            Limpiar Filtros
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import setFilterS from "@/mixins/setfilter";
import iconic from "@/plugins/iconic/iconic.vue";
export default {
  name: "selectSearchAndCityRoom",
  props: {
    title: String,
    placeholder: String,
    role: String,
    flag: Boolean,
    clean: Function,
  },
  components: { iconic },
  mixins: [setFilterS],
  data() {
    return {
      dinamicTitle: "",
      city: "",
      office: "",
      search: "",
      loading: false,
      wait: null,
    };
  },
  computed: {
    offices() {
      if (!this.city) return [];
      return this.$store.getters["offices/getData"].filter((x) => x?.cityName === this.city);
    },
    cleanFilter() {
      if (this.$userToken?.role === "superadmin" || this.isCoorSpecial(this.$userToken)) return Object.keys(this.$route.query).length;
      else {
        if (this.$route.query["search"] || this.$route.query["office"]) {
          return true;
        }
      }
      return false;
    },
  },
  methods: {
    onSearch() {
      clearTimeout(this.wait);
      this.wait = setTimeout(() => {
        this.emitChange();
      }, 300);
    },
    emitChange() {
      this.$emit("onSubmit");
    },
    async changeCity(evt) {
      let sede = document.getElementById("id");
      sede.value = "";
      this.office = "";
      this.setFilter({ srcElement: sede });
      this.setFilter(evt);
    },
    deleteFilter() {
      this.search = "";
      this.city = "";
      this.office = "";
      this.setFilter("reset");
      if (!(this.$userToken.role === "superadmin" || this.isCoorSpecial(this.$userToken))) {
        this.city = this.$userToken.city;
        this.setFilter({ srcElement: { name: "city", value: this.city } });
      }
      if (this.flag) {
        this.clean();
      }
      this.emitChange();
    },
  },
  beforeMount: async function() {
    const { office, city, search } = this.$route.query;
    this.city = city || "";
    this.office = office || "";
    this.search = search || "";
    if (!(this.$userToken.role === "superadmin" || this.isCoorSpecial(this.$userToken))) {
      this.city = this.$userToken.city;
      this.setFilter({ srcElement: { name: "city", value: this.city } });
    }
  },
};
</script>

<style lang="scss">
.filters {
  margin-top: 10px;
  select {
    background-color: $white;
  }
  &__inputs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__cont {
    display: flex;
    width: 100%;
    height: 30px;
    margin: 5px;
    justify-content: center;
  }
  &__input,
  &__delete {
    border: none;
    background: center;
  }
  &__title {
    margin: 0 0 10px 0;
  }
  &__delete {
    text-align: center;
    color: $primary-color;
  }
  &__input {
    margin-left: 5px;
    width: 100%;
  }
  .ginput,
  [ginput] {
    font-size: 13px;
    padding: 0 8px;
  }
  @include tabletWidth() {
    &__inputs {
      flex-direction: row;
    }
    &__delete {
      flex-shrink: 0;
      font-size: 14px;
    }
  }
}
</style>
