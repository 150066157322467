<template>
  <section class="roomD">
    <div class="roomD__wrapper">
      <selected @onSubmit="getQuery" class="roomD__select" :title="''" :placeholder="'Buscar Habitacion'" />
      <percentages :query="$route.query" />
      <div class="roomD__card gcard" v-if="screen.width > 650 && Object.keys(getRoomsMain).length">
        <div class="roomD__container">
          <div class="roomD__titles" v-for="(title, index) in titles" :key="index">
            <p class="roomD__text">{{ title }}</p>
          </div>
        </div>
      </div>
      <div class="roomD__wrapper" v-if="screen.width < 650">
        <div class="roomD__containerCard gcard" v-for="(city, ind) in getRoomsMain" :key="ind">
          <p class="roomD__containerName">{{ ind }}</p>
          <mobileRoomCard :room="room" v-for="(room, ind) in [...city]" :key="room._id + ind" />
        </div>
      </div>
      <div class="roomD__wrapper" v-else>
        <div class="roomD__containerCard gcard" v-for="(city, ind) in getRoomsMain" :key="ind + city">
          <p class="roomD__containerName">{{ ind }}</p>
          <div v-for="(room, ind) in city" :key="room._id + ind">
            <roomCard :room="room"></roomCard>
            <span :class="'roomD__line ' + (ind === city.length - 1 ? 'roomD__line--finish' : '')"></span>
          </div>
        </div>
      </div>
      <div class="roomD__notRecords" v-if="!Object.keys(getRoomsMain).length">
        <p class="noFoundInfo">No hay registros para esta consulta.</p>
      </div>
      <div class="spinner" v-if="isLoading"></div>
      <div v-if="hasMore && !isLoading">
        <button class="adminview__more click-on-bottom" @click="getMore">ver más</button>
      </div>
    </div>
  </section>
</template>

<script>
  import roomCard from "./roomCard.vue";
  import mobileRoomCard from "./mobileRoomCard.vue";
  import selected from "../../../components/widgets/searchFilterCityRoom.vue";
  import percentages from "../../../components/widgets/PercentCityRoom.vue";
  export default {
    name: "roomFixed",
    components: { roomCard, selected, mobileRoomCard, percentages },

    data() {
      return {
        titles: ["HABITACIÓN", "MAÑANA", "TARDE", "NOCHE"],
        page: 1,
        city: "",
        office: "",
        roomName: "",
        isLoading: false,
        rooms: {},
        hasMore: false,
      };
    },
    computed: {
      screen: function() {
        return this.$store.state.screen;
      },
      getRoomsMain: {
        set(data) {
          let _rooms = { ...this.rooms };
          for (let city in data) {
            if (_rooms.hasOwnProperty(city)) {
              _rooms[city] = [..._rooms[city], ...data[city]];
            } else {
              _rooms[city] = [...data[city]];
            }
          }
          this.rooms = _rooms;
        },
        get() {
          return this.rooms;
        },
      },
    },
    methods: {
      resetAll() {
        this.page = 1;
        this.city = "";
        this.office = "";
        this.roomName = "";
        this.isLoading = false;
        this.rooms = {};
        this.hasMore = false;
      },
      getMore: async function() {
        this.getRoomsMain = await this.getRooms((this.page += 1));
      },
      async getQuery() {
        this.resetAll();
        let { city, office, search } = this.$route.query;
        this.city = city || "";
        this.office = office || "";
        this.roomName = search || "";
        this.page = 1;
        this.getRoomsMain = await this.getRooms(this.page);
      },
      getRooms: async function(page) {
        this.isLoading = true;
        const {
          data: { response, error },
        } = await this.$axios.get(`availability?page=${page}&city=${this.city}&office=${this.office}&roomName=${this.roomName}&limit=50`);
        if (error) {
          this.$alerts.dataSendError({ message: error });
          return [];
        }
        this.hasMore = response.hasMore;
        this.isLoading = false;
        return response.dataCities;
      },
    },
    beforeMount: async function() {
      await this.getQuery();
    },
  };
</script>

<style lang="scss">
  .roomD {
    min-height: 100vh;
    &__line {
      display: block;
      width: 100%;
      height: 1px;
      background-color: $silver;
      &--finish {
        display: none;
      }
    }
    &__container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      text-align: center;
      font-weight: bold;
    }
    &__titles {
      &:first-child {
        text-align: left;
      }
    }
    &__containerCard,
    &__card {
      margin-top: 15px;
    }
    &__containerCard {
      position: relative;
      padding-top: $mpadding * 3;
    }
    &__card {
      padding: $mpadding/2 $mpadding * 1.5;
    }
    &__containerName {
      position: absolute;
      top: 0;
      left: 0;
      width: 115px;
      padding: $mpadding/3;
      text-align: center;
      text-transform: uppercase;
      background-color: $lipstick;
      color: $white;
      border-radius: 5px 0;
    }
    &__notRecords {
      margin-top: $mpadding * 2;
      text-align: center;
    }
  }
</style>
